const userRouter = {
  path: '/user',
  component: () => import('@/views/user/user.vue'),
  children: [
    {
      path: 'user-info',
      component: () => import('@/views/user/subPages/user-info/index.vue'),
      name: 'user-info',
      meta: {
        title: '用户信息',
        icon: 'icon-icon_yonghuxinxi-n',
      },
    },
    {
      path: 'edit-password',
      component: () => import('@/views/user/subPages/edit-password/index.vue'),
      name: 'edit-password',
      meta: {
        title: '修改密码',
        icon: 'icon-icon_xiugaimima-n',
      },
    },
    {
      path: 'edit-phone',
      component: () => import('@/views/user/subPages/edit-phone/index.vue'),
      name: 'edit-phone',
      meta: {
        title: '手机绑定',
        icon: 'icon-icon_shoujibangding-n',
      },
    },
    {
      path: 'relation',
      component: () => import('@/views/user/subPages/relation/index.vue'),
      name: 'relation',
      meta: {
        title: '关联管理',
        icon: 'icon-icon_guanlianguanli-n',
      },
    },
  ],
};

export default userRouter;
