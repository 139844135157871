/**
 * @file vue原型属性/方法注入
 * 在组件中调用: this.xxx
 */
import Vue from 'vue';

import { MessageBox, Message, Loading, Dialog } from 'element-ui';

import BatchEntry from './components/batchEntry';

import BatchExport from './components/batchExport';

import BindPhone from './views/back-stage/components/tlg';

// 修改dialog closeOnClickModal 默认值为 false
if (Dialog.props.closeOnClickModal && Dialog.props.closeOnClickModal.default) {
  Dialog.props.closeOnClickModal.default = false;
}

// 注册全局指令
Vue.use(Loading);

// 设置 MessageBox 默认样式 & 属性
MessageBox.setDefaults({
  customClass: 'ql-message-box',
  closeOnClickModal: false,
});

Object.assign(Vue.prototype, {
  /**
   * @desc 弹框
   * 详细配置见https://element.eleme.cn/#/zh-CN/component/message-box
   */
  $msgbox: MessageBox,

  /**
   * @desc 消息提示
   * @example this.$message.success("xxx");
   * 详细配置见https://element.eleme.cn/#/zh-CN/component/message
   */
  $message: Message,

  /**
   * @desc 批量录入方法
   * @example this.$batchEntry({});
   * @param {String} type 批量录入类型
   * @param {function} callback 批量导入回调函数
   */
  $batchEntry: BatchEntry,

  /**
   * @desc 批量导出方法
   * @example this.$batchExport({});
   * @param {String} type 批量录入类型
   */
  $batchExport: BatchExport,

  /**
   * @desc 三级等保--绑定手机
   * @example this.$tlg.check();
   */
  $tlg: BindPhone,
});
