import { login, logout, getCurrentLogonInfo, getUserPermission } from '@/api/common';
import { getBindTencent } from '@/api/user';
import { getToken, setToken, removeToken, removeAllCookies } from '@/utils/cookies';
import { resetRouter } from '@/router';
import { allPermissions } from '@/utils/permission';

const state = {
  token: getToken(),
  userInfo: {},
  userStatus: false, // 用户信息是否获取
  permissions: [],
  isSchoolSuper: '', // 是否为超级管理员
  importDataShow: '', // 是否显示导入数据按钮
  mainIndexShow: '', // 是否展示首页按钮
  bindTencentUsername: '', // 绑定智慧校园账号
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
    token === '' ? removeToken() : setToken(token);
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions;
  },
  SET_ROLE: (state, data) => {
    state.isSchoolSuper = data.isSchoolSuper;
    state.importDataShow = data.importDataShow;
    state.mainIndexShow = data.mainIndexShow;
  },
  SET_USER_STATUS: (state, status) => {
    state.userStatus = status;
  },
  SET_BIND_TENCENT: (state, data) => {
    state.bindTencentUsername = data;
  },
};

const actions = {
  // user login
  login({ commit }, { userInfo, method = 'post' }) {
    return new Promise((resolve, reject) => {
      login(userInfo, method)
        .then((response) => {
          const data = response.result;
          commit('SET_TOKEN', data.jsessionid);
          commit('SET_USER_INFO', data);
          setToken(data.jsessionid);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // get user info
  getUserInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getCurrentLogonInfo({})
        .then((response) => {
          const { result } = response;

          if (!result) {
            reject('获取用户信息失败，请重新登录');
          }

          const user = result;

          commit('SET_USER_INFO', user);
          commit('SET_USER_STATUS', true);

          resolve({
            userInfo: user,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // get user permission
  getUserPermission({ commit }) {
    return new Promise((resolve, reject) => {
      getUserPermission({})
        .then((response) => {
          const { result } = response;

          if (!result) {
            reject('获取用户权限信息失败，请重新登录');
          }

          const { menuList, isSchoolSuper, importDataShow, mainIndexShow } = result;

          const permissionsCodes = menuList.map((x) => x.code);

          const permissions = [];

          for (const key in allPermissions) {
            if (permissionsCodes.includes(key)) {
              let permission = Object.assign({}, allPermissions[key]);
              permission.code = key;
              permissions.push(permission);
            }
          }

          commit('SET_PERMISSIONS', permissions);
          commit('SET_ROLE', {
            isSchoolSuper,
            importDataShow,
            mainIndexShow,
          });

          resolve(permissions);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // get bind tencent user
  getBindTencentUser({ commit }) {
    return new Promise((resolve, reject) => {
      getBindTencent()
        .then(({ result }) => {
          const bindUserName = result?.bindUserName ?? '';
          commit('SET_BIND_TENCENT', bindUserName);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          commit('SET_TOKEN', '');
          removeAllCookies();
          // commit('SET_PERMISSIONS', []);
          resetRouter();

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '');
      // commit('SET_PERMISSIONS', []);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
