import Cookies from 'js-cookie';

// token

const TokenKey = '$token';
const LESSONID = 'lessonId';

function getCTX() {
  const path = window.$ctx;
  let n = path.length - 1;
  while (path[n] === '/') {
    n--;
  }
  return path.substring(0, n + 1);
}

function getPath() {
  const path = window.location.pathname;
  const CTX = getCTX();
  if (path.indexOf(CTX) !== -1) {
    return CTX;
  } else {
    return `/${path.split('/')[1]}`;
  }
}

export function getToken() {
  return Cookies.get(TokenKey);
}

// js-cookie default 为 '/', 转为根据当前路径
export function setToken(token) {
  return Cookies.set(TokenKey, token, {
    path: getPath(),
  });
}

export function removeToken() {
  Cookies.remove(TokenKey);
  Cookies.remove(TokenKey, {
    path: getPath(),
  });
  // 清理所有cookie
  let res = '';
  const paths = window.location.pathname.split('/');
  for (let i = 0, len = paths.length - 1; i < len; i++) {
    res += `${res === '/' ? '' : '/'}${paths[i]}`;
    Cookies.remove(TokenKey, {
      path: res,
    });
  }
  return;
}

// 清除全部cookies
export function removeAllCookies() {
  const keys = document.cookie.match(/[^ =;]+(?=\=)/g); //eslint-disable-line
  if (keys) {
    for (let i = 0; i < keys.length; i++) {
      Cookies.remove(keys[i]);
    }
  }
  return;
}

// 学科ID
// 获取学科ID
export function getLessonId() {
  return Cookies.get(LESSONID);
}
// 设置学科ID
export function setLessonId(ID) {
  return Cookies.set(LESSONID, ID);
}
