<template>
  <el-dialog
    class="upload_dialog"
    custom-class="ql-dialog"
    title="批量录入"
    :visible.sync="visible"
    width="700px"
    top="200px"
    append-to-body
    :before-close="handleBeforeClose"
  >
    <div class="upload_tip">
      <span class="iconfont icon-icon_tishi"></span>
      <span class="upload_remind">请下载模板，并按照模板要求录入数据上传</span>
      <span class="upload_warn">【注意，{{ uploadTip }}】</span>
      <el-button size="mini" style="width: 80px" @click="handleDownload">下载模版</el-button>
    </div>
    <div class="upload_container">
      <el-upload
        class="upload_upload"
        ref="upload"
        action="https://jsonplaceholder.typicode.com/posts/"
        drag
        :accept="acceptType"
        :auto-upload="false"
        :file-list="fileList"
        :show-file-list="false"
        :before-upload="handleBeforeUpload"
        :on-change="handleChange"
      >
        <div class="upload_trigger" slot="trigger" @click="handleTrigger">
          <svg class="icon upload_svg" aria-hidden="true">
            <use xlink:href="#icon-tuozhuaishangchuan"></use>
          </svg>
          <div class="upload__text">点击或将文件拖拽到这里上传</div>
          <div class="upload__tip" v-if="!fileList || fileList.length === 0">支持扩展名：.xls .xlsx</div>
          <div class="upload_file" v-else>
            <svg class="icon upload_icon" aria-hidden="true">
              <use xlink:href="#icon-biaoge"></use>
            </svg>
            <p class="upload_file-name" :title="fileList[0].name">{{ fileList[0].name }}</p>
          </div>
        </div>
      </el-upload>
    </div>
    <span slot="footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" :disabled="!fileList || fileList.length === 0" size="small"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { Dialog, Button, Upload } from 'element-ui';
export default {
  data() {
    return {
      fileList: [], //文件列表
      acceptType: '', //文件类型
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    uploadTip: {
      type: String,
      default: '一次最多上传1500条数据',
    },
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Upload.name]: Upload,
  },
  methods: {
    // 下载模版
    handleDownload() {
      this.$emit('download');
    },

    handleCancel() {
      // 清除状态
      this.fileList = [];
      this.acceptType = '';
      this.$emit('cancel');
    },

    handleConfirm() {
      this.$emit('upload', this.fileList[0]);
    },

    handleBeforeClose(done) {
      this.handleCancel();
      done();
    },

    handleBeforeUpload(file) {
      // accept=".xls,.xlsx"
      const { name } = file;
      const regex = /\.(xlsx)$/gi;
      if (!regex.test(name)) {
        this.$message.error('应上传xls/xlsx文件');
        return false;
      }
    },

    handleChange(file) {
      const { name } = file;
      const regex = /\.(xlsx?)$/gi;

      this.acceptType = '';

      if (!regex.test(name)) {
        this.$message.error('应上传xls/xlsx文件');
        return false;
      } else {
        this.fileList = [file];
      }
    },

    handleTrigger() {
      this.acceptType = '.xls,.xlsx';
    },

    resetFileList() {
      this.fileList = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.upload_dialog {
  &::v-deep {
    .el-dialog__body {
      padding: 32px;
    }
  }
}

.upload_tip {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background: #f6f8fb;
  border-radius: 4px;
  font-weight: 400;
  padding: 0 12px;
  line-height: 1;

  .icon-icon_tishi {
    font-size: 20px;
    color: #ffa51f;
    margin-right: 5px;
  }

  .upload_remind {
    color: #2c2f2e;
  }

  .upload_warn {
    color: #e95156;
    margin-right: 12px;
  }

  &::v-deep {
    .el-button {
      border: 1px solid #38c3a1;
      color: #38c3a1;
    }
  }
}

.upload_container {
  width: 100%;
  height: 260px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #dee0e3;
  margin-top: 20px;
}

.upload_upload {
  height: 100%;
  &::v-deep {
    .el-upload,
    .el-upload-dragger {
      width: 100%;
      height: 100%;
    }

    .el-upload-dragger {
      height: 100%;
      border: none;
    }
  }

  .upload_trigger {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .upload_svg {
    width: 50px;
    height: 50px;
  }

  .upload__text {
    font-size: 16px;
    font-weight: 400;
    color: #2c2f2e;
    line-height: 22px;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .upload__tip {
    font-weight: 400;
    color: #bec3c2;
    line-height: 20px;
  }

  .upload_file {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 400;
    color: #6d7171;
    line-height: 20px;

    &-name {
      max-width: 550px;
      @include ellipsis(auto);
    }

    .upload_icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
}
</style>
