/**
 * @file 公共接口
 */
import request from '@/utils/request';

/**
 * @desc 登录之后判断路由跳转
 * @param { String } j_username 用户名@学校编码用户名(shortUserId@schoolKey)
 * @param { String } j_password 密码，当get请求方式登录并进行rsa加密后的密码传入时，需要进一步url encode下，因为加密后的密码存在特殊字符，不进行encode，传给后台有问题
 * @param { Boolean } isAppLogon 是否客户端登录(true: 返回结果为json格式; false或者不传: 返回结果为html页面)
 * @param { Number } deviceType 设备类型, 固定传5, 表示java设备(云层超级管理员校层超级管理员可以不传)
 * @param { Number } moduleId 模块id, 固定21, 表示备课(云层超级管理员校层超级管理员可以不传)
 * @param { Boolean } isRsaPassword
 * @param { String } thirdProjectCode 三方登录编码，安徽、宁夏等三方登录需要传参
 * @param { String } thirdToken 三方登录token，安徽、宁夏等三方登录需要传参
 * @param { String } thirdLoginName 三方登录用户名，宁夏登录需要传参
 * @param { String } schoolKey 学校Key，宁夏登录需要传参
 */
export const login = (data, method) => {
  return request({
    url: '/j_spring_security_check',
    method: method,
    data,
  });
};
/**
 * @desc 退出登录
 */
export const logout = (data) => {
  return request({
    url: '/j_spring_security_logout',
    method: 'post',
    data,
  });
};
/**
 * @desc 登录之后判断路由跳转
 */
export const getLoginDispatch = (data) => {
  return request({
    url: '/main/jsonDispacth/index.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 获取用户信息
 */
export const getUserInfo = (data) => {
  return request({
    url: '/user/json/config.do',
    method: 'get',
    data,
  });
};

/**
 * @desc 获取当前登录用户信息
 */
export const getCurrentLogonInfo = (data) => {
  return request({
    url: '/currentLogonInfo.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 获取当前登陆帐号所有的菜单权限
 */
export const getUserPermission = (data) => {
  return request({
    url: '/json/baseManage.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 获取学校列表
 * @param { String } schoolNameFuzzy 学校名称， 搜索框内容
 */
export const getSchoolByKeyword = (data) => {
  return request({
    url: '/server/school/register/search/list/json.do',
    method: 'post',
    data,
  });
};
/**
 * @desc 当前服务器版本
 */
export const getLicense = (data) => {
  return request({
    url: '/license/json.do',
    method: 'get',
    data,
  });
};

/** home */
/**
 * @desc 获取用户首页菜单
 */
export const getHomeMenu = (data) => {
  return request({
    url: '/main/jsonData/index.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 系统默认升年级
 * @param { String } schoolId 学校id
 * @param { String } data 升年级配置json，包含年级学段id和是否分班信息（选中班级的升级方式为不分班升级，未选中班级的升级方式为分班升级）
 */
export const postGradeUpgrade = (data) => {
  return request({
    url: '/grade/upgrade.do',
    method: 'post',
    data,
  });
};

/**
 * @desc 导入批量文件
 */
export const importBatchData = (url, data, cancelToken) => {
  return request({
    url,
    method: 'POST',
    cancelToken,
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
    isUpload: true, // 上传标识
    timeout: 90000,
  });
};

/**
 * @desc 获取异步导入任务的状态
 */
export const getImportAsyncStatus = (data) => {
  return request({
    url: '/getImportAsyncStatus.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 通用POST方法下载
 */
export const downloadByPost = (url, data = {}, cancelToken) => {
  return request({
    url,
    method: 'POST',
    data,
    cancelToken,
    responseType: 'blob',
    needResponse: true,
  });
};
