/**
 * @file 公共方法
 */
import JSEncrypt from 'jsencrypt';
import { getLicense } from '@/api/common';
import { getToken, setToken } from '@/utils/cookies';
import { downloadByPost } from '@/api/common';

/**
 * @name getURLParams
 * @desc 获取链接参数
 * @param {String} url
 * @returns {Object} url参数
 */
export function getURLParams(url) {
  if (!url) url = window.location.hash;
  let params = {};
  url.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => (params[k] = v));
  return params;
}

/**
 * @name setURLParams
 * @desc param对象转换为url 参数
 * @param {Object} param
 * @returns {String} url参数
 */
export function setURLParams(param) {
  let url = '';
  for (let i in param) {
    url += `${url.includes('?') ? '&' : '?'}${i}=${param[i]}`;
  }
  return url;
}

/**
 * @name formatTime
 * @desc 时间戳转给yyyy-mm-dd
 * @param {String} timestamp 时间戳
 * @returns {} yyyy-mm-dd格式日期
 */
export function formatTime(timestamp) {
  const date = timestamp ? new Date(timestamp) : new Date();
  const year = formatNum(date.getFullYear());
  const month = formatNum(date.getMonth() + 1);
  const day = formatNum(date.getDate());
  return `${year}-${month}-${day}`;

  function formatNum(data) {
    return data < 10 ? `0${data}` : data;
  }
}

/**
 * @name rsaEncryption
 * @desc rsa 加密
 * @param {String} password 密码
 */

export const rsaEncryption = (password) => {
  const encryptor = new JSEncrypt();
  const publicKey =
    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCxOEhBGnY9xYds4b52gKkk0yjG32hSD/PRTP++5xGh42EWERvOcMqSwqK3vmxcmxC/SSolml3FZAF7auxdnnhtXwaXnjdhb1fo61h6ZnaRhgL7vjlVuCAnBy+8JT80T2nyPTqHnTzNjdGgSDsArIOH35RAiTUbUVrLnD4kBmOhwwIDAQAB';
  encryptor.setPublicKey(publicKey); // 设置公钥

  return encryptor.encrypt(password); // 对内容进行加密
};

/**
 * @name getHostUrl
 * @desc 获取hostUrl(用于数据库备份/离线升级相关的接口)
 */
export const getHostUrl = () => {
  var hostUrl = location.origin.replace(location.port, '');
  if (location.protocol == 'http:') {
    hostUrl = hostUrl + '13632/';
  } else if (location.protocol == 'https:') {
    hostUrl = hostUrl + ':13633/';
  }

  // 开发环境使用该基准路径
  if (process.env.NODE_ENV === 'development') {
    hostUrl = 'http://44.qljy.com:13632'; // 基准路径需要向后台要
  }
  return hostUrl;
};

/**
 * @name compareHostUrl
 * @desc 比较两个 hostUrl 是否完全相同
 */
export const compareHostUrl = (target, origin) => {
  if (typeof target !== 'string' || typeof origin !== 'string') {
    return undefined;
  }
  if (target.replace(/\/$/, '') === origin.replace(/\/$/, '')) {
    return true;
  }
  return false;
};

/**
 * @name checkNetworkStatus
 * @desc 检查网络状态（用于数据库备份/离线升级/安徽省平台数据同步的任务前置接口）
 */
export const checkNetworkStatus = (successCB, failCB) => {
  if (window.navigator.onLine) {
    getLicense({})
      .then(() => {
        successCB();
      })
      .catch(() => {
        failCB();
      });
  } else {
    failCB();
  }
};

/**
 * @name checkToken
 * @desc 检查token，当第三方免登录时，则将url中的token 赋到cookie中
 * @param {String} route 路由
 */
export const checkToken = (route) => {
  const hasToken = getToken();

  if (!hasToken) {
    const { query } = route;
    const { token } = query;
    if (token) {
      setToken(token);
    }
  }
};

/**
 * @name downloadFileByA
 * @desc 通过 a 标签下载文件
 * @param {String} url  下载链接
 * @param {String} name  文件名
 * @param {Object} option 配置
 */
export function downloadFileByA(url, name = '', option = {}) {
  let eleLink = document.createElement('a');
  eleLink.href = url;
  if (name) {
    eleLink.download = name;
  }
  eleLink.style.display = 'none';
  Object.assign(option, eleLink);
  document.body.appendChild(eleLink);
  eleLink.click();
  document.body.removeChild(eleLink);
}

/**
 * @name downloadFileByPost
 * @desc POST下载文件
 * @param {String} url  下载链接
 * @param {String} data  传参
 * 参考智慧作业
 */
export function downloadFileByPost(url, data = {}, cancelToken) {
  return new Promise((resolve, reject) => {
    downloadByPost(url, data, cancelToken)
      .then((response) => {
        const res = response.data;
        if (res instanceof Blob) {
          if (res.type === 'application/json') {
            const fileReader = new FileReader();
            fileReader.readAsText(res, 'utf-8');
            fileReader.onerror = (e) => {
              console.error(e);
              reject(`文件读取错误: ${fileReader.error}`);
              fileReader.abort();
            };
            fileReader.onload = (e) => {
              const result = JSON.parse(e.target.result);
              if (result.status !== 0 && result.msg) {
                reject(result.msg);
              }
            };
          } else {
            const name = response.headers.filename ? decodeURI(response.headers.filename.split('.')[0]) : '';
            // 如果是IE10及以上，不支持download属性，采用msSaveOrOpenBlob方法，但是IE10以下也不支持msSaveOrOpenBlob
            if ('msSaveOrOpenBlob' in navigator) {
              navigator.msSaveOrOpenBlob(res, name);
              return;
            }
            const url = URL.createObjectURL(res);
            downloadFileByA(url, name);
            URL.revokeObjectURL(url);
            resolve();
          }
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * @name checkTextOverflowing
 * @desc 判断文本是否超过限制长度(text-overflow: ellipsis)
 * @param dom 计算节点
 * @return {boolean} 是否超过
 */
export const checkTextOverflowing = (dom) => {
  if (!dom) return;
  // use range width instead of scrollWidth to determine whether the text is overflowing
  // to address a potential FireFox bug: https://bugzilla.mozilla.org/show_bug.cgi?id=1074543#c3
  const range = document.createRange();
  range.setStart(dom, 0);
  range.setEnd(dom, dom.childNodes.length);
  const rangeWidth = range.getBoundingClientRect().width;
  const padding = (parseInt(dom.style.paddingLeft, 10) || 0) + (parseInt(dom.style.paddingRight, 10) || 0);

  return rangeWidth + padding > dom.offsetWidth || dom.scrollWidth > dom.offsetWidth;
};

/**
 * @name 复制文本
 * @desc 将某段文本复制到粘贴板
 */
export const copyText = (text) => {
  if (!text) return false;
  if (!document.execCommand('copy')) return false;

  let inputContainer = document.createElement('input');
  inputContainer.style = `
    position: fixed;
    top: -100px;
    left: -100px;
    opacity: 0;
  `;
  inputContainer.value = text;
  document.body.appendChild(inputContainer);
  inputContainer.select();
  // 将选中内容复制到粘贴板
  document.execCommand('copy');
  document.body.removeChild(inputContainer);
  return true;
};
