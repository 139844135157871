export const authorityManageRoute = {
  path: 'authority-manage',
  name: 'authority-manage',
  component: () => import('@/views/authority-manage/index.vue'),
  meta: {
    permissionCode: 'authority-manage',
    title: '权限管理',
    icon: 'icon-icon_cebianlan_quanxianguanli',
  },
  children: [
    {
      path: 'cross-course',
      name: 'cross-course',
      component: () => import('@/views/authority-manage/subPages/cross-course/index.vue'),
      meta: {
        title: '空中课堂',
      },
    },
    {
      path: 'video-application',
      name: 'video-application',
      component: () => import('@/views/authority-manage/subPages/video-application/index.vue'),
      meta: {
        title: '视频应用',
      },
    },
    {
      path: 'school-resource',
      name: 'school-resource',
      component: () => import('@/views/authority-manage/subPages/school-resource/index.vue'),
      meta: {
        title: '校本资源',
      },
    },
    {
      path: 'resource-access',
      name: 'resource-access',
      component: () => import('@/views/authority-manage/subPages/resource-access/index.vue'),
      meta: {
        title: '资源接入',
      },
    },
  ],
};
