// import { Message } from 'element-ui';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style

import router from './router';
import store from './store';

import { getToken } from '@/utils/cookies'; // get token from cookie
import { checkToken } from '@/utils/common';
import { getRouteByPermission } from '@/utils/permission';
import { ningxiaLogout } from '@/utils/sync'; // third project function

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const loginPath = '/login';
const homePath = '/home';
const whiteList = [loginPath, '/sync-redirect']; // no redirect whitelist
const defaultTitle = '后台管理'; // default title

router.beforeEach(async (to, from, next) => {
  // change page title
  document.title = to.meta.requiresTitle ? to.meta.title : defaultTitle;

  // start progress bar
  NProgress.start();

  // determine whether the user has logged in
  checkToken(to);
  const hasToken = getToken();

  if (hasToken) {
    // 有token
    if (whiteList.includes(to.path)) {
      // 登录状态下，跳转登录页默认重定向到home
      if (to.path === loginPath) {
        next(homePath);
      } else {
        next();
      }

      NProgress.done();
    } else {
      const hasPermissions = store.getters.permissions?.length > 0;
      const userStatus = store.getters.userStatus;

      try {
        // 根据userInfo 加载动态路由
        if (!userStatus) {
          await store.dispatch('user/getUserInfo');
        }

        if (hasPermissions || !to.path.includes('back-stage')) {
          next();
        } else {
          await getRouteByPermission();

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true });
        }
      } catch (error) {
        // 重置token并跳转到登录页
        await store.dispatch('user/resetToken');

        // Message.error(error || 'Has Error');

        if (to.path === homePath) {
          // path 为home，直接跳转login
          next(loginPath);
        } else {
          // path 不为home，带redirect跳转login
          next(`${loginPath}?redirect=${to.path}`);
        }

        NProgress.done();
      }
    }
  } else {
    // 没有token
    if (whiteList.includes(to.path)) {
      if (to.path === loginPath && localStorage.getItem('ningxiaToken')) {
        // 退出登录时，同步登出宁夏平台
        ningxiaLogout();
      } else {
        // 白名单内，直接跳转
        next();
      }
    } else {
      if (to.path === homePath) {
        // path 为home，直接跳转login
        next(loginPath);
      } else {
        // path 不为home，带redirect跳转login
        next(`${loginPath}?redirect=${to.path}`);
      }
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});

router.onError((error) => {
  console.log(`error:${error}`);
  console.dir(router);
  const pattern = /Loading chunk .* failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});
