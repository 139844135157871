import Vue from 'vue';
import main from './batchEntry';

let BatchEntryConstructor = Vue.extend(main);

let instance = null;

function initInstance() {
  instance = new BatchEntryConstructor({
    el: document.createElement('div'),
  });
  document.body.appendChild(instance.$el);
}

/**
 * @desc 批量录入方法
 * @example this.$batchEntry({});
 * @param {String} type 批量录入类型
 * @param {function} callback 批量导入回调函数
 */
const BatchEntry = function (options = {}) {
  if (!instance) {
    initInstance();
  }

  instance.type = options.type;
  instance.callback = options.callback;
  instance.uploadTip = options.uploadTip || '一次最多上传1500条数据';
  instance.UNIT = options.UNIT || '个';

  Vue.nextTick(() => {
    instance.init();
  });
};

/**
 * @desc 批量录入状态检查
 * @example this.$batchEntry.checkTask();
 */
BatchEntry.checkTask = () => {
  if (!instance) {
    initInstance();
  }

  instance.checkStatus = true;

  Vue.nextTick(() => {
    instance.polling();
  });
};

export default BatchEntry;
