/**
 * @file 第三方平台处理方法
 */

/**
 * @name ningxiaLogout
 * @desc 登出宁夏平台（宁夏平台要求：需要浏览器定位到登出地址才能登出，ajax方式不可行）
 */
export const ningxiaLogout = () => {
  const ningxiaToken = localStorage.getItem('ningxiaToken');
  localStorage.removeItem('ningxiaToken');
  window.location.href = `${window.$ctx}/OAuth2/ningxia/web/logout.do?token=${ningxiaToken}`;
};
