import { basicRoute } from './basic';
import { authorityManageRoute } from './authority-manage';

const backStageRouter = {
  path: '/back-stage',
  component: () => import('@/views/back-stage/index.vue'),
  children: [
    {
      path: 'init-school',
      name: 'init-school',
      component: () => import('@/views/back-stage/subPages/init-school/index.vue'),
      meta: {
        permissionCode: 'init-school',
        title: '初始化学校',
        icon: 'icon-icon_cebianlan_chushihuaxuexiao',
      },
    },
    {
      path: 'all-pass-single-sign-on-config',
      name: 'all-pass-single-sign-on-config',
      component: () => import('@/views/back-stage/subPages/all-pass-single-sign-on-config/index.vue'),
      meta: {
        permissionCode: 'all-pass-single-sign-on-config',
        title: '全通单点登录配置',
        icon: 'icon-icon_cebianlan_quantongdandiandenglupeizhi',
      },
    },
    {
      path: 'all-connected-platform-access-config',
      name: 'all-connected-platform-access-config',
      component: () => import('@/views/back-stage/subPages/all-connected-platform-access-config/index.vue'),
      meta: {
        permissionCode: 'all-connected-platform-access-config',
        title: '全连接平台接入配置',
        icon: 'icon-icon_cebianlan_quanlianjiepingtaijierupeizhi',
      },
    },
    {
      path: 'third-party-school-account-sync',
      name: 'third-party-school-account-sync',
      component: () => import('@/views/back-stage/subPages/third-party-school-account-sync/index.vue'),
      meta: {
        permissionCode: 'third-party-school-account-sync',
        title: '第三方学校帐号同步',
        icon: 'icon-icon_disanfangzhanghao',
      },
    },
    {
      path: 'total-authority-manage',
      name: 'total-authority-manage',
      component: () => import('@/views/back-stage/subPages/total-authority-manage/index.vue'),
      meta: {
        permissionCode: 'total-authority-manage',
        title: '权限管理',
        icon: 'icon-icon_cebianlan_quanxianguanli',
      },
    },
    {
      path: 'school-data-push-config',
      name: 'school-data-push-config',
      component: () => import('@/views/back-stage/subPages/school-data-push-config/index.vue'),
      meta: {
        permissionCode: 'school-data-push-config',
        title: '学校数据推送配置',
        icon: 'icon-icon_cebianlan_xuexiaoshujutuisongpeizhi',
      },
    },
    {
      path: 'Anhui-supervision-platform-access-config',
      name: 'Anhui-supervision-platform-access-config',
      component: () => import('@/views/back-stage/subPages/Anhui-supervision-platform-access-config/index.vue'),
      meta: {
        permissionCode: 'Anhui-supervision-platform-access-config',
        title: '安徽监管平台接入配置',
        icon: 'icon-icon_cebianlan_anhuijianguanpingtaijieru',
      },
    },
    {
      path: 'delete-pen',
      name: 'delete-pen',
      component: () => import('@/views/back-stage/subPages/delete-pen/index.vue'),
      meta: {
        permissionCode: 'delete-pen',
        title: '删除点阵笔',
        icon: 'icon-ic_cebianlan_shanchudianzhenbi',
      },
    },
    basicRoute, // 基础数据
    {
      path: 'equipment-manage',
      name: 'equipment-manage',
      component: () => import('@/views/back-stage/subPages/equipment-manage'),
      meta: {
        permissionCode: 'equipment-manage',
        title: '设备管理',
        icon: 'icon-icon_cebianlan_shebeiguanli',
      },
      children: [
        {
          path: 'box',
          name: 'box',
          component: () => import('@/views/back-stage/subPages/equipment-manage/subPages/box/index.vue'),
          meta: {
            title: '青鹿盒子',
          },
        },
        {
          path: 'pen',
          name: 'pen',
          component: () => import('@/views/back-stage/subPages/equipment-manage/subPages/pen/index.vue'),
          meta: {
            title: '点阵笔',
          },
        },
      ],
    },
    authorityManageRoute, // 权限管理
    {
      path: 'register-education-bureau',
      name: 'register-education-bureau',
      component: () => import('@/views/back-stage/subPages/register-education-bureau/index.vue'),
      meta: {
        permissionCode: 'register-education-bureau',
        title: '注册教育局',
        icon: 'icon-icon_cebianlan_zhucejiaoyuju',
      },
    },
    {
      path: 'register-school',
      name: 'register-school',
      component: () => import('@/views/back-stage/subPages/register-school/index.vue'),
      meta: {
        permissionCode: 'register-school',
        title: '注册学校',
        icon: 'icon-icon_cebianlan_chushihuaxuexiao',
      },
    },
    {
      path: 'remote-upgrade',
      name: 'remote-upgrade',
      component: () => import('@/views/back-stage/subPages/remote-upgrade/index.vue'),
      meta: {
        permissionCode: 'remote-upgrade',
        title: '远程升级',
        icon: 'icon-icon_cebianlan_yuanchengshengji',
      },
    },
    {
      path: 'third-party-manage',
      name: 'third-party-manage',
      component: () => import('@/views/back-stage/subPages/total-authority-manage/index.vue'),
      meta: {
        permissionCode: 'third-party-manage',
        title: '第三方管理',
        icon: 'icon-icon_cebianlan_disanfangguanli',
      },
    },
    {
      path: 'school-server-config',
      name: 'school-server-config',
      component: () => import('@/views/back-stage/subPages/school-server-config/index.vue'),
      meta: {
        hidden: true,
      },
    },
    {
      path: 'education-init-config',
      name: 'education-init-config',
      component: () => import('@/views/back-stage/subPages/education-init-config/index.vue'),
      meta: {
        hidden: true,
      },
    },

    // {
    //   path: '',
    //   component: () => import('@/views/back-stage/subPages//index.vue'),
    //   name: '',
    //   meta: {
    //     permissionCode: ''
    //   },
    // },
  ],
};

export default backStageRouter;
