/**
 * @file 用户信息
 */
import request from '@/utils/request';

/** 个人中心 */

/**
 * @desc 修改头像，获取头像信息
 */
export const getUserAvatar = (data) => {
  return request({
    url: '/user/json/imgUpload.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 修改头像，保存
 * @param {String} x 左上角x坐标
 * @param {String} y 左上角y坐标
 * @param {String} w 宽
 * @param {String} h 高
 * @param {String} fileType 文件类型类，1：图片类型，
 * @param {String} fileUrl 头像文件url，编辑已有头像文件，只需要传头像url， 上传新图片就需要传file
 * @param {Array}  file 文件，二进制，编辑已有头像文件，只需要传头像url， 上传新图片就需要传file
 */
export const saveUserAvatar = (data) => {
  return request({
    url: '/user/setPhoto.do',
    method: 'post',
    data,
  });
};
/**
 * @desc 修改密码
 * @param {String} oldPassword 旧密码
 * @param {String} password 新密码, 8-24位，至少包含数字、字母或特殊字符中两种方式
 * @param {String} confirmPassword 确认密码, 8-24位，至少包含数字、字母或特殊字符中两种方式
 */
export const resetPassword = (data) => {
  return request({
    url: '/app/user/passsword/save.do',
    method: 'post',
    data,
  });
};
/**
 * @desc 找回密码
 */
export const updatePassword = (data) => {
  return request({
    url: '/app/open/updatePwd.do',
    method: 'post',
    data,
  });
};
/**
 * @desc 手机绑定 获取手机号
 */
export const getUserMobile = (data) => {
  return request({
    url: '/user/json/setMobile.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 手机绑定 发送短信获取验证码
 * @param {String} mobile 新手机号码
 * @param {String} type 短信类型，1：修改/绑定手机号码
 */
export const sendCaptcha = (data, serverUrl = '') => {
  return request({
    url: `${serverUrl}/app/captcha/captcha.do`,
    method: 'post',
    data,
  });
};
/**
 * @desc 手机绑定 保存绑定手机号码
 * @param {String} mobile 新手机号码
 * @param {String} code 验证码
 */
export const saveUserMobile = (data) => {
  return request({
    url: '/saveMobileBinding.do',
    method: 'post',
    data,
  });
};
/**
 * @desc 管理员手机绑定，不需验证码
 * @param {String} mobile 新手机号码
 */
export const saveUserMobileMark = (data) => {
  return request({
    url: '/saveMobileBindingOnlyMobile.do',
    method: 'post',
    data,
  });
};
/**
 * @desc 管理员手机验证码校验
 * @param {String} mobile 手机号码
 * @param {String} code 验证码
 */
export const verifyUserCode = (data) => {
  return request({
    url: '/verifySmsCode.do',
    method: 'post',
    data,
  });
};
/**
 * @desc 关联管理 获取关联状态
 */
export const getOAuth2bindingInfo = (data) => {
  return request({
    url: '/OAuth2/country/bindingInfo.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 关联管理 解除关联状态
 */
export const unbindOAuth2 = (data) => {
  return request({
    url: '/OAuth2/country/unbind.do',
    method: 'post',
    data,
  });
};
/**
 * @desc //学校学科列表查询
 */
export const getLessonAllList = (data) => {
  return request({
    url: '/json/lessonAllList.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 获取用户任教信息
 */
export const getLessonList = (data) => {
  return request({
    url: '/wktClass/teacherLessons.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 腾讯智慧校园绑定 获取绑定信息
 */
export const getBindTencent = (data) => {
  return request({
    url: '/txCampus/bindInfo.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 腾讯智慧校园绑定 解除绑定
 */
export const removeBindTencent = (data) => {
  return request({
    url: '/txCampus/remove.do',
    method: 'post',
    data,
  });
};
