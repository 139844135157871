const getters = {
  token: (state) => state.user.token,
  userInfo: (state) => state.user.userInfo,
  hostUrl: (state) => state.user.userInfo.hostUrl,
  userStatus: (state) => state.user.userStatus,
  permissions: (state) => state.user.permissions,
  isBindTencent: (state) => !!state.user.bindTencentUsername,
  routes: (state) => state.route.routes,
};

export default getters;
