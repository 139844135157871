<template>
  <div class="batchEntry">
    <!-- 上传文件 -->
    <UploadDialog
      ref="upload"
      :uploadTip="uploadTip"
      v-model="uploadVisible"
      @cancel="handleUploadCancel"
      @download="handleDownloadLink"
      @upload="handleUpload"
    ></UploadDialog>
    <!-- 数据校验 -->
    <BatchProgress v-model="progressVisible" @cancel="handleProgressCancel" :activeType="activeType"></BatchProgress>
    <!-- 反馈结果 -->
    <TipDialog
      v-model="tipVisible"
      title="批量录入"
      :type="tipType"
      :confirmText="tipText"
      @confirm="handleConfirmTip"
      @close="handleCloseTip"
    >
      <p class="batchEntry_text">
        成功录入{{ batchResult.successCount }}{{ UNIT }}{{ batchResult.taskName
        }}<span v-if="batchResult.failCount > 0" class="batchEntry_fail"
          >失败{{ batchResult.failCount }}{{ UNIT }}</span
        >
      </p>
    </TipDialog>
  </div>
</template>

<script>
// axios
import axios from 'axios';
// methods
import { downloadFileByA, downloadFileByPost } from '@/utils/common';
// components
import TipDialog from '@/components/tipDialog';
import UploadDialog from './uploadDialog.vue';
import BatchProgress from './batchProgress.vue';

// apis
import { importBatchData, getImportAsyncStatus } from '@/api/common';

const TYPE_MAP = {
  teach: {
    name: '任教',
    uploadLink: '/importClassLesson.do',
    url: 'importTemplate/teachTemplate.xlsx',
    fileName: '任教-录入模版',
  },
  class: {
    name: '班级',
    uploadLink: '/chonggou/importClass.do',
    url: 'importTemplate/classTemplate.xlsx',
    fileName: '班级-录入模版',
  },
  classUser: {
    name: '班级-学生关系',
    uploadLink: '/chonggou/importClassUser.do',
    url: 'importTemplate/classUserTemplate.xlsx',
    fileName: '班级名单-录入模版',
  },
  student: {
    name: '学生',
    uploadLink: '/chonggou/importStudent.do',
    url: 'importTemplate/studentTemplate.xlsx',
    fileName: '学生-录入模版',
  },
  teacher: {
    name: '教师',
    uploadLink: '/importTeacher.do',
    url: 'importTemplate/teacherTemplate.xlsx',
    fileName: '教师-录入模版',
  },
  subject: {
    name: '学科',
    uploadLink: '/chonggou/importLesson.do',
    url: 'importTemplate/subjectTemplate.xlsx',
    fileName: '学科-录入模版',
  },
  pen: {
    name: '点阵笔',
    uploadLink: '/importDianZhenBi.do',
    url: 'importTemplate/penTemplate.xlsx',
    fileName: '点阵笔-录入模版',
  },
};

const CANCEL_UPLOAD_TEXT = '取消上传文件';

export default {
  name: 'BatchEntry',
  data() {
    return {
      type: '', // 导入类型
      checkStatus: false,
      uploadVisible: false,
      progressVisible: false,
      tipVisible: false,

      activeType: -1,

      tipType: 'success',
      tipText: '确定',

      batchResult: {},
      callback: null,
      axiosSource: null,

      timer: null,

      UNIT: '个',
      uploadTip: '一次最多上传1500条数据',
    };
  },
  components: {
    UploadDialog,
    BatchProgress,
    TipDialog,
  },
  methods: {
    init() {
      this.axiosSource = null;
      this.$refs.upload && this.$refs.upload.resetFileList();
      this.uploadVisible = true;
      this.polling();
    },

    // 取消批量录入
    handleUploadCancel() {
      this.uploadVisible = false;
    },

    // 进度弹窗关闭
    handleProgressCancel(errorType) {
      switch (errorType) {
        case 'cancelError':
          this.axiosSource && this.axiosSource.cancel(CANCEL_UPLOAD_TEXT);
          break;
        case 'importError':
        case 'uploadError':
          // 接口报错清掉文件
          this.$refs.upload && this.$refs.upload.resetFileList();
          break;
      }
      this.axiosSource = null;

      this.uploadVisible = true;

      setTimeout(() => {
        this.progressVisible = false;
      }, 50);
    },

    // 下载模版
    handleDownloadLink() {
      const item = TYPE_MAP[this.type];
      let url = `${item.url}`;
      downloadFileByA(url, item.fileName);
    },

    // 上传文件
    handleUpload(file) {
      this.axiosSource = axios.CancelToken.source(); // axios cancel标识
      let formdata = new FormData();
      formdata.append('file', file.raw);
      this.activeType = -1;
      this.progressVisible = true;
      setTimeout(() => {
        this.uploadVisible = false;
      }, 50);
      // 上传文件
      importBatchData(TYPE_MAP[this.type].uploadLink, formdata, this.axiosSource.token)
        .then((res) => {
          if (res && res.status === 0) {
            this.activeType = 0;
            this.polling();
          }
        })
        .catch((error) => {
          if (!error || error.message !== CANCEL_UPLOAD_TEXT) {
            this.handleProgressCancel('uploadError');
          }
        });
    },

    // 轮询进度
    polling() {
      this.timer && clearTimeout(this.timer);
      const fn = () => {
        this.timer = setTimeout(() => {
          getImportAsyncStatus().then((res) => {
            const { status, errorMsg, taskCode } = res.result;
            // 刷新页面进入检查
            if (this.checkStatus) {
              if (taskCode === '') {
                return;
              } else {
                this.progressVisible = true;
              }
            }
            // 进入判断
            switch (status) {
              case 0:
                this.activeType = 3;
                this.handleCloseProgress(res.result);
                break;
              case 1:
                this.$message({
                  message: errorMsg,
                  type: 'warning',
                  customClass: 'batchEntry-high-level',
                });
                this.handleProgressCancel('importError');
                break;
              default:
                this.activeType = status - 1;
                fn();
                break;
            }
          });
        }, 1000);
      };
      fn();
    },

    // status === 1时
    handleCloseProgress(item) {
      const { status, failCount } = item;
      this.batchResult = item;

      if (status === 1 || failCount > 0) {
        this.tipType = 'warning';
        this.tipText = '下载录入失败结果';
      } else {
        this.tipType = 'success';
        this.tipText = '确定';
      }
      this.tipVisible = true;
      setTimeout(() => {
        this.progressVisible = false;
      });
    },

    handleConfirmTip() {
      if (this.tipType === 'success') {
        this.handleCloseTip();
      } else {
        this.downloadImportErrorRecord();
        this.callback && this.callback();
      }
    },

    // 下载错误文件
    downloadImportErrorRecord() {
      downloadFileByPost('/downloadImportErrorRecord.do', {
        importRecordId: this.batchResult.importRecordId,
      }).catch((error) => {
        error && this.$message.error(error);
      });
    },

    // 关闭提示弹窗
    handleCloseTip() {
      this.$refs.upload && this.$refs.upload.resetFileList();
      this.callback && this.callback();
      this.tipVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped src="./batchEntry.scss"></style>
