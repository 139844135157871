/**
 * @file 权限与路由相关的公共常量和方法
 */
import store from '@/store';
import router from '@/router';

// 权限列表
export const allPermissions = {
  // 校层超级管理员
  'init-school': {
    type: 'route',
    id: 120,
    title: '初始化学校',
  },
  'all-pass-single-sign-on-config': {
    type: 'route',
    id: 145,
    title: '全通单点登录配置',
  },
  'all-connected-platform-access-config': {
    type: 'route',
    id: 147,
    title: '全连接平台接入配置',
  },
  'third-party-school-account-sync': {
    type: 'route',
    id: 158,
    title: '第三方学校帐号同步',
  },
  'total-authority-manage': {
    type: 'route',
    id: 148,
    title: '权限管理',
  },
  'school-data-push-config': {
    type: 'route',
    id: 149,
    title: '学校数据推送配置',
  },
  'Anhui-supervision-platform-access-config': {
    type: 'route',
    id: 155,
    title: '安徽监管平台接入配置',
  },
  'delete-pen': {
    type: 'route',
    id: 161,
    title: '删除点阵笔',
  },
  // 校层管理员
  'basic-data': {
    type: 'route',
    id: 150,
    title: '基础数据',
  },
  'equipment-manage': {
    type: 'route',
    id: 151,
    title: '设备管理',
  },
  'authority-manage': {
    type: 'route',
    id: 152,
    title: '权限管理',
  },
  // 云层超级管理员
  'register-education-bureau': {
    type: 'route',
    id: 118,
    title: '注册教育局',
  },
  'register-school': {
    type: 'route',
    id: 119,
    title: '注册学校',
  },
  'remote-upgrade': {
    type: 'route',
    id: 142,
    title: '远程升级',
  },
  'third-party-manage': {
    type: 'route',
    id: 144,
    title: '第三方管理',
  },
};

/**
 * @name getRouteByPermission
 * @desc 获取用户信息，用户权限来设置动态路由
 */
export const getRouteByPermission = async () => {
  const permissions = await store.dispatch('user/getUserPermission');

  // generate accessible routes map based on roles
  const accessRoutes = await store.dispatch('route/generateRoutes', permissions);

  // dynamically add accessible routes
  accessRoutes.map((route) => {
    router.addRoute(route);
  });
};

export default allPermissions;
