import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/* Router Modules */
import backStageRouter from './modules/back-stage.js';
import userRouter from './modules/user.js';

/**
 * constantRoutes
 * a base page that does not have permission requirements
 */
export const constantRoutes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/login.vue'),
    meta: {
      requiresTitle: true,
      title: '青鹿教学云平台-登录',
    },
  },
  {
    path: '/home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/index.vue'),
    meta: {
      requiresTitle: true,
      title: '青鹿教学云平台',
    },
  },
  {
    path: '/sync-redirect',
    component: () => import('@/views/sync-redirect/index.vue'),
  },
  userRouter,
];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user permission
 */
export const asyncRoutes = [
  /** when your routing map is too long, you can split it into small modules **/
  backStageRouter,

  // 404 page must be placed at the end !!!
  {
    path: '*',
    redirect: '/login',
    meta: {
      hidden: true,
    },
  },
];

const createRouter = () =>
  new Router({
    routes: constantRoutes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
