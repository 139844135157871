/**
 * @file basic模块接口
 */
import request from '@/utils/request';

/**
 * @desc 查看当前学校详情
 */
export const getSchoolShow = (data) => {
  return request({
    url: '/json/schoolShow.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 查看当前学校详情
 * @param { Number } schoolId 学校id
 * @param { String } schoolName 学校名称
 * @param { Number } schoolType 学校类类型id
 * @param { String } schoolShortName 学校代码
 */
export const saveSchool = (data) => {
  return request({
    url: '/schoolSave.do',
    method: 'POST',
    data,
  });
};
/**
 * @desc 学科列表分页查询
 * @param { Number } pageSize 每页大小
 * @param { String } pageIndex 当前页
 */
export const getLessonList = (data) => {
  return request({
    url: '/json/lessonList.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 保存学科信息
 */
export const saveLesson = (data, isEdit = false) => {
  return request({
    url: isEdit ? '/chonggou/lessonEditSave.do' : '/chonggou/lessonSave.do',
    method: 'POST',
    data,
  });
};
/**
 * @desc 删除学科
 * @param { String } lessonId 学科id
 */
export const delLesson = (data) => {
  return request({
    url: '/chonggou/lessonDelete.do',
    method: 'POST',
    data,
  });
};
/**
 * @desc 年级分页查询
 * @param { String } pageSize 每页大小
 * @param { String } pageIndex 当前页
 */
export const getGradePageJson = (data) => {
  return request({
    url: '/json/gradePageJson.do',
    method: 'POST',
    data,
  });
};
/**
 * @desc 年级长信息
 */
export const getGradeEdit = (data) => {
  return request({
    url: '/json/gradeEdit.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 编辑年级信息，年级保存
 * @param { String } gradeId 年级id
 * @param { String } gradeUserId1 级长用户id
 */
export const saveGrade = (data) => {
  return request({
    url: '/gradeSave.do',
    method: 'POST',
    data,
  });
};
/**
 * @desc 班级分页查询
 * @param { String } pageIndex 当前页
 * @param { String } pageSize 每一页显示多少条记录
 * @param { String } gradeId 年级id，旧逻辑是点击年级后， 把年级id记录在cookie里面，下一次初始化页面的时候，通过cookie里面传入后台， 这样子就达到默认选中最后一次点击的年级
 */
export const getClassList = (data) => {
  return request({
    url: '/classListNew.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 新增班级界面数据初始化
 * @param { String } gradeId 当前年级id
 */
export const getClassAdd = (data) => {
  return request({
    url: '/json/classAdd.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 保存班级信息
 * @param { String } classId 班级id（编辑的时候需要传入）
 * @param { String } gradeId 年级id
 * @param { String } className 班级名称
 * @param { String } classNo 班级序号，这里暂时写死0
 * @param { String } beginYear 学年
 * @param { String } adviser.userId 班主任用户id
 * @param { String } currentSchoolYear 当前学年
 */
export const saveClass = (data) => {
  return request({
    url: '/classSave.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 查询班级下的所有老师和学生，老师排序在前
 * @param { Number } classId 班级ID
 */
export const getTeacherStudentList = (data) => {
  return request({
    url: '/json/class/teacherStudentList.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 删除班级(逻辑删除)
 * @param { Number } classId 班级ID
 */
export const delClass = (data) => {
  return request({
    url: '/logicClassDelete.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 查询班级名单
 * @param { Number } classId 班级id
 */
export const getClassUserList = (data) => {
  return request({
    url: '/chonggou/classUser/classUserList.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 新增班级名单
 * @param { Number } classId 班级id
 * @param { String } userId 学生id
 */
export const addNewClassUser = (data) => {
  return request({
    url: '/chonggou/classUser/addNewClassUser.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 转班班级名单
 * @param { Number } oldClassId 原班级id
 * @param { Number } userId 学生id
 * @param { Number } newClassId 新班级id
 */
export const changeClassUser = (data) => {
  return request({
    url: '/chonggou/classUser/changeClass.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 删除班级名单
 * @param { String } classId 班级id
 * @param { String } userId 学生id
 */
export const deleteClassUser = (data) => {
  return request({
    url: '/chonggou/classUser/deleteClassUser.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 查询班级列表 , 并且排除当前班级
 * @param { Number } gradeId 年级id
 * @param { Number } curUserId 当前学生id
 */
export const getClassUserListData = (data) => {
  return request({
    url: '/chonggou/classUser/classListData.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 新增班级
 * @param { String } className 班级名称
 * @param { Number } teacherId 班主任用户id
 * @param { Number } gradeId 年级id
 */
export const addClass = (data) => {
  return request({
    url: '/chonggou/classAddSave.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 编辑班级
 * @param { String } className 班级名称
 * @param { Number } teacherId 班主任id
 * @param { String } classId 班级id
 */
export const updateClass = (data) => {
  return request({
    url: '/chonggou/classEditSave.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 删除班级
 * @param { String } classId 班级id
 */
export const deleteClass = (data) => {
  return request({
    url: '/chonggou/classDelete.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 更新班级内部学号
 * @param { String } classUserJson 需要更新班级内部学号的列表，Json字符串，classId 是班级id，innerStudentNo 是新的班级内部学号，userId是学生id
 */
export const updateClassUser = (data) => {
  return request({
    url: '/chonggou/classUser/updateClassUser.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 查询非本班的学生
 * @param { Number } classId 班级id
 * @param { String } studentUserName 学生名字关键字，注意这里是必填， 只能搜索有关键字的， 防止要渲染几千个学生的下拉列表
 */
export const selectNotInClassStudent = (data) => {
  return request({
    url: '/chonggou/classUser/selectNotInClassStudent.do',
    method: 'GET',
    data,
  });
};

/** 基础数据-任教 */
/**
 * @desc 初始化数据（包含任教信息和年级信息）
 * @param { String } pageIndex 当前页码
 * @param { String } pageSize 每一页显示的记录数量
 * @param { String } gradeId 年级id，有选择年级就传入年级id， 没有选择年级就不需要传入
 * @param { String } classId 班级id，有选择班级就传入年级id， 没有选择班级就不需要传入
 */
export const getClassLessonListNew = (data) => {
  return request({
    url: '/classLessonListNew.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 任教列表分页查询（只包含任教信息）
 * @param { String } pageSize 每页大小
 * @param { String } pageIndex 当前页
 * @param { String } gradeId 年级id，有选择年级就传入年级id， 没有选择年级就不需要传入
 * @param { Number } classId 班级id，有选择班级就传入年级id， 没有选择班级就不需要传入
 */
export const getClassLessonList = (data) => {
  return request({
    url: '/json/classLessonList.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 删除任教信息
 * @param { Number } classLessonId 任教信息id
 */
export const delClassLesson = (data) => {
  return request({
    url: '/classLessonDeleteNew.do',
    method: 'POST',
    data,
  });
};
/**
 * @desc 新增任教信息界面查询教师列表
 * @param { String } userName 教师姓名，模糊搜索
 */
export const getTeacherListByUserName = (data) => {
  return request({
    url: '/json/teacherListByUserName.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 新增任教信息界面数据初始化
 */
export const getClassLessonAdd = (data) => {
  return request({
    url: '/json/classLessonAdd.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 保存任教信息
 * @param { Number } classes.gradeId 年级id，新增的时候必填， 编辑的时候不需要传
 * @param { Number } classId 班级id，新增的时候必填， 编辑的时候不需要传
 * @param { Number } lessonId 学科id ，新增的时候必填， 编辑的时候不需要传
 * @param { String } userId 教师id
 * @param { String } classes.classId 班级id，新增的时候必填， 编辑的时候不需要传
 * @param { String } classLessonId 任教信息id， 编辑的时候是必填项
 */
export const saveClassLesson = (data) => {
  return request({
    url: '/classLessonSaveNew.do',
    method: 'POST',
    data,
  });
};

/** 基础数据-家长 */
/**
 * @desc 家长列表初始化数据（包含年级信息和家长信息
 * @param { String } pageIndex 页码
 * @param { String } pageSize 每页显示的记录
 */
export const getParentListNew = (data) => {
  return request({
    url: '/parentListNew.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 家长列表分页查询
 * @param { Number } pageSize 每页大小
 * @param { Boolean } pageIndex 当前页
 * @param { Number } gradeId 年级id,不传默认查询所有
 * @param { String } classId 班级id,不传默认查询所有
 * @param { String } parentName 家长姓名（搜索框内容）
 */
export const getParentList = (data) => {
  return request({
    url: '/json/parentList.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 删除家长
 * @param { String } parentId 家长id
 */
export const delParent = (data) => {
  return request({
    url: '/parentDelete.do',
    method: 'POST',
    data,
  });
};

/** 基础数据-教室 */
/**
 * @desc 教室列表界面数据初始化
 * @param { String } pageIndex 当前页码
 * @param { String } pageSize 每页显示的记录数
 */
export const getClassroomListNew = (data) => {
  return request({
    url: '/classrom/json/page/view.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 教室分页查询
 * @param { Number } pageSize 每页大小
 * @param { Number } pageIndex 当前页
 * @param { Number } gradeId 年级id，选择的年级
 * @param { Number } classId 班级id，选择的班级
 */
export const getClassroomList = (data) => {
  return request({
    url: '/classrom/page/json.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 保存教室
 * @param { String } id 教室主键id，修改时必传
 * @param { String } name 教室名称
 * @param { String } classId 班级id
 * @param { String } typeId 教室类型(1公共教室, 2固定教室)
 */
export const saveClassroom = (data) => {
  return request({
    url: '/classrom/edit/save.do',
    method: 'POST',
    data,
  });
};
/**
 * @desc 删除教室
 * @param { String } id 教室id
 */
export const delClassroom = (data) => {
  return request({
    url: '/classrom/delete.do',
    method: 'POST',
    data,
  });
};
/** 基础数据-教师 */

/**
 * @desc 教师界面初始化数据（包含年级信息）
 * @param {Number} pageIndex 当前页码
 * @param {Number} pageSize 每页显示多少条记录
 * @param {String} gradeId 年级id
 */
export const getTeacherListNew = (data) => {
  return request({
    url: '/teacherListNew.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 教师列表分页查询（只有教师数据）
 * @param {Number} pageIndex 当前页码
 * @param {Number} pageSize 每页显示多少条记录
 * @param {String} gradeId 年级id
 * @param {String} userName 搜索框里面的内容
 */
export const getTeacherList = (data) => {
  return request({
    url: '/json/teacherListNew.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 保存老师信息（用于新增、编辑）
 * @param { String } teacherNo 教职工号
 * @param { String } userName 姓名
 * @param { Number } mobileNo 手机号
 * @param { Number } sex 性别，中文 男、女
 * @param { Number } userId 教师id（用于编辑）
 */
export const saveTeacher = (data) => {
  return request({
    url: '/teacherSaveNew.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 编辑教师回显信息
 * @param { String } teacherId 教师id
 */
export const getTeacherInfo = (data) => {
  return request({
    url: '/json/teacherEdit.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 删除老师信息
 * @param { String } teacherId 教师id
 */
export const deleteTeacher = (data) => {
  return request({
    url: '/teacherDeleteNew.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 修改教师密码
 * @param { Number } teacherId 教师id
 * @param { String } password 密码, 8-24位，至少包含数字、字母或特殊字符中两种方式
 * @param { Number } optionType 操作类型（0输入新密码，1使用默认密码）
 */
export const saveTeacherPassword = (data) => {
  return request({
    url: '/saveTeacherPassword.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 批量重置用户密码（教师）
 * @param { String } userIds 用户id,多个用逗号隔开
 * @param { Number } userType 用户类型(1学生，4教师)
 */
export const batchResetTeacherPassword = (data) => {
  return request({
    url: '/user/resetBatchPassword.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 根据查询条件重置密码（教师）
 * @param { String } userName 模糊查询条件
 * @param { Number } gradeId 年级id
 * @param { String } pwd 默认密码不需要填写 新密码填写为新密码的值
 */
export const resetTeacherPassword = (data) => {
  return request({
    url: '/user/updatePasswordBatchForTeacher.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 批量删除老师信息
 * @param { String } teacherIds 教师id，多个用户逗号隔开
 */
export const batchDeleteTeacher = (data) => {
  return request({
    url: '/teacherBatchDeleteNew.do',
    method: 'POST',
    data,
  });
};

/** 基础数据-学生 */

/**
 * @desc 学生列表分页查询
 * @param {Number} pageIndex 当前页码
 * @param {Number} pageSize 每页显示多少条记录
 * @param {String} gradeId 年级id，不传查全部
 * @param {String} classId 班级id，不传查全部
 * @param {String} keyword 学生姓名，搜索框内容
 * @param {String} unclassifiedFlag
当前的是否需要筛选未归类的学生 , 0:未归类， 1：已归类（正常查询）。注意，这个参数是最高优先级，而且是必填。 若为0， 则不管年级id和班级id
 */
export const getStudentList = (data) => {
  return request({
    url: '/chonggou/selectPageStudentListChongGou.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 放开限制，查询学校所有的年级
 */
export const getGradeList = () => {
  return request({
    url: '/chonggou/gradeList.do',
    method: 'GET',
  });
};

/**
 * @desc 放开限制，查询年级下的所有班级，用于搜索条件
 * @param { String } gradeId 年级id
 */
export const getClassListByGradeId = (data) => {
  return request({
    url: '/chonggou/classList.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 选择年级后刷新班级数据
 * @param { String } gradeId 年级id
 */
export const getClassListData = (data) => {
  return request({
    url: '/json/classListData.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 编辑回显数据（包含年级信息、班级信息和学生信息）
 * @param {String} userId 学生id
 */
export const getStudentInfo = (data) => {
  return request({
    url: '/chonggou/getStudentInfo.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 新增学生帐号
 * @param {Number} userId 用户id（编辑时必传），新增不传
 * @param {Number} studentNo 学号
 * @param {String} userName 姓名
 * @param {Number} sex 性别
 * @param {String} password  密码，可以自定义密码， 不传就使用默认密码
 */
export const addStudent = (data) => {
  return request({
    url: '/chonggou/addStudent.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 编辑后保存学生信息
 * @param {Number} userId 用户id
 * @param {Number} studentNo 学号
 * @param {String} userName 姓名
 * @param {Number} sex 性别
 * @param {String} password  密码，可以自定义密码， 不传就使用默认密码
 */
export const editStudent = (data) => {
  return request({
    url: '/chonggou/editStudent.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 删除学生
 * @param { String } studentId 学生id
 */
export const deleteStudent = (data) => {
  return request({
    url: '/chonggou/deletestudent.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 修改学生密码
 * @param {String} studentId 学生id
 * @param {String} password 密码, 8-24位，至少包含数字、字母或特殊字符中两种方式
 * @param {Number} optionType 操作类型（0输入新密码，1使用默认密码）
 */
export const saveStudentPassword = (data) => {
  return request({
    url: '/saveStudentPassword.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 修改密码获取学生基本信息（仅仅包含学生信息）
 * @param { String } studentId 学生id
 */
export const getStudentInfoOne = (data) => {
  return request({
    url: '/json/studentChange.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 批量删除学生信息
 * @param { Number } studentIds 学生id，多个id， 可以用英文逗号隔开
 */
export const batchDeleteStudent = (data) => {
  return request({
    url: '/chonggou/studentBatchDelete.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 批量重置用户密码（学生）
 * @param { String } userIds 用户id,多个用逗号隔开
 * @param { Number } userType 用户类型(1学生，4教师)
 */
export const batchResetStudentPassword = (data) => {
  return request({
    url: '/user/resetBatchPassword.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 根据查询条件重置密码（学生）
 * @param { String } keyword 关键字查询
 * @param { Number } gradeId 年级id
 * @param { Number } classId 班级id
 * @param { Number } unclassifiedFlag 当前的是否需要筛选未归类的学生 , 0:未归类， 1：已归类（正常查询）
 * @param { String } pwd 默认密码不需要填写 新密码填写为新密码的值
 */
export const resetStudentPassword = (data) => {
  return request({
    url: '/user/updatePasswordBatchForStudent.do',
    method: 'POST',
    data,
  });
};

/** 基础数据-升年级 */
/**
 * @desc 显示升年级配置界面数据初始化
 */
export const getUpgradeSetting = (data) => {
  return request({
    url: '/grade/json/upgrade/setting/edit/view.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 保存升年级配置信息
 * @param { String } data 格式如初始值一样， gradeData 是所有学段的年级是否有勾选， upgradeTime是提示升年级的时间
 */
export const saveUpgradeSetting = (data) => {
  return request({
    url: '/grade/upgrade/setting/save.do',
    method: 'POST',
    data,
  });
};

/** 数据库备份 */

/**
 * @desc 启动数据库备份
 * @param {String} host hostUrl
 */
export const startBackup = (hostUrl, data) => {
  return request({
    baseURL: hostUrl,
    url: '/backupMysql',
    method: 'GET',
    data,
  });
};
/**
 * @desc 查询数据库备份任务状态
 */
export const getBackupTaskStatus = (hostUrl) => {
  return request({
    baseURL: hostUrl,
    url: '/status',
    method: 'GET',
  });
};

/** 离线升级 */

/**
 * @desc 查询升级状态
 */
export const checkUpgradeResult = (hostUrl) => {
  return request({
    baseURL: hostUrl,
    url: '/tipOnce',
    method: 'GET',
  });
};

/**
 * @desc 查询当前版本信息
 */
export const getCurrentVersion = () => {
  return request({
    url: '/webapp/m/getCurrentVersion.do',
    method: 'GET',
  });
};
/**
 * @desc go项目-离线升级-查询最新备份时间
 */
export const getLastBackupTime = (hostUrl) => {
  return request({
    baseURL: hostUrl,
    url: '/lastBackupTime',
    method: 'GET',
  });
};
/**
 * @desc 查询数据库备份任务状态
 */
export const getUpgradeTaskStatus = (hostUrl) => {
  return request({
    baseURL: hostUrl,
    url: '/status',
    method: 'GET',
  });
};
/**
 * @desc 获取重启状态
 */
export const getRestartStatus = () => {
  return request({
    url: '/client/init/school/status.do',
    method: 'GET',
    ignoreTimeoutError: true,
  });
};

/**
 * @desc 通知服务器设置上传安装包的状态
 */
export const setUpgradeStatus = (hostUrl) => {
  return request({
    baseURL: hostUrl,
    url: '/setUpgradeStatus',
    method: 'GET',
  });
};

/**
 * @desc 启动离线升级
 * @param {File} file 升级包文件
 * @param {String} host hostUrl
 * @param {Number} backup 1. 若升级失败使用已备份的数据库进行回滚 0. 否
 */
export const uploadUpgradeFile = (hostUrl, data) => {
  return request({
    baseURL: hostUrl,
    url: '/upgrade',
    method: 'POST',
    isUpload: true,
    data,
  });
};

/** 安徽省平台数据同步 */

/**
 * @desc 任务ID查询任务状态
 * @param { String } schoolKey 学校key
 */
export const getNewestTaskBySchoolKey = (data) => {
  return request({
    url: '/anhuisync/getNewestTaskBySchoolKey.do',
    method: 'get',
    data,
  });
};

/**
 * @desc 安徽用户ID查询最近的一次任务状态
 * @param {String} userId 安徽用户ID/第三方token
 */
export const getNewestTaskByAnhuiUserId = (data) => {
  return request({
    url: '/anhuisync/getNewestTaskByAnhuiUserId.do',
    method: 'get',
    data,
  });
};

/**
 * @desc 学校key查询最近的一次任务状态
 * @param { Number } taskId 任务ID
 */
export const getTaskByTaskId = (data) => {
  return request({
    url: '/anhuisync/getTaskByTaskId.do',
    method: 'get',
    data,
  });
};

/**
 * @desc 学校key启动数据同步
 * @param { String } schoolKey 学校key
 */
export const startSync = (data) => {
  return request({
    url: '/anhuisync/startSyncBySchoolKey.do',
    method: 'get',
    data,
  });
};

/**
 * @desc 安徽用户ID启动数据同步
 * @param { String } userId 安徽第三方登录返回的用户ID
 */
export const startSyncByAnhuiUserId = (data) => {
  return request({
    url: '/anhuisync/startSyncByAnhuiUserId.do',
    method: 'get',
    data,
  });
};

/**
 * @desc 同步全连接基础数据
 */
export const avaSyncSchool = (data) => {
  return request({
    url: '/ava/syncSchool.do',
    method: 'get',
    data,
  });
};
