import axios from 'axios';
import qs from 'qs';
import { Message } from 'element-ui';
import store from '@/store';
import router from '@/router';
import { getURLParams } from '@/utils/common';
import { getToken } from '@/utils/cookies';

const STATUS_SUCCESS = 0; // 成功
// const STATUS_FAIL = 1; // 失败
const STATUS_SESSION_EXPIRED = 2; // 会话过期
// const STATUS_NO_PERMISSION = 3; // 没有权限

if (process.env.NODE_ENV === 'development') {
  window.$ctx = 'http://192.168.4.250:16999/school/';
  // window.$ctx = '';
}
let baseURL = window.$ctx;

if (getURLParams().isMock) {
  baseURL = 'http://rap2.qljy.com:7777/app/mock/1/';
}

// create an axios instance
const service = axios.create({
  baseURL: baseURL, // url = base url + request url
  timeout: 0, // 不限制时间
});

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// request interceptor
service.interceptors.request.use(
  (config) => {
    // 默认data
    config.data = config.data || {};
    config.data.isAppLogon = true;

    // token处理
    if (store.getters.token) {
      if (config.isUpload) {
        // 上传文件特殊处理
        config.data.append('token', getToken());
      } else {
        config.data.token = getToken() || store.getters.token;
      }
    }

    // 请求头添加schoolKey参数
    if (store.getters.userInfo && store.getters.userInfo.schoolKey) {
      config.headers['schoolKey'] = store.getters.userInfo.schoolKey;
    }

    // 参数处理
    switch (config.method) {
      case 'post':
        if (config.data.constructor !== FormData) {
          config.data = config.data || {};
          config.data = qs.stringify(config.data, {
            arrayFormat: 'repeat',
          });
        }
        break;
      case 'get':
        config.params = config.data || {};

        delete config.data;
        break;
      default:
        break;
    }
    return config;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const needResponse = response.config.needResponse || false;
    const res = needResponse ? response : response.data;

    // 会话过期
    if (res.status === STATUS_SESSION_EXPIRED) {
      Message.closeAll();
      res.msg && Message.error(res.msg);

      store.dispatch('user/resetToken').then(() => {
        router.replace('/login');
      });

      return Promise.reject(new Error(res.msg || 'Error'));
    }

    if (res.status !== STATUS_SUCCESS && !needResponse) {
      Message.closeAll();
      res.msg && Message.error(res.msg);

      return Promise.reject(new Error(res.msg || 'Error'));
    }

    return res;
  },
  (error) => {
    console.log(`response error: `);
    console.log(error);

    const ignoreTimeoutError = (error.config && error.config.ignoreTimeoutError) || false; // 是否忽略超时报错

    // 网络超时
    if (error.message.includes('timeout')) {
      // ignoreTimeoutError:true 时，忽略超时报错
      if (!ignoreTimeoutError) {
        Message.closeAll();
        error.message && Message.error(`网络超时`);
      }
      return Promise.reject(error);
    }

    Message.closeAll();
    error.message && Message.error(error.message);
    return Promise.reject(error);
  }
);

export default service;
