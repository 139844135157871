export const basicRoute = {
  path: 'basic-data',
  name: 'basic-data',
  redirect: 'basic-data/school',
  component: () => import('@/views/basic/index.vue'),
  meta: {
    permissionCode: 'basic-data',
    title: '基础数据',
    icon: 'icon-icon_cebianlan_jichushuju',
  },
  children: [
    {
      path: 'school',
      name: 'basic-school',
      component: () => import('@/views/basic/subPages/school/index.vue'),
      meta: {
        title: '学校',
      },
    },
    {
      path: 'subject',
      name: 'basic-subject',
      component: () => import('@/views/basic/subPages/subject/index.vue'),
      meta: {
        title: '学科',
      },
    },
    {
      path: 'grade',
      name: 'basic-grade',
      component: () => import('@/views/basic/subPages/grade/index.vue'),

      meta: {
        title: '年级',
      },
    },
    {
      path: 'class',
      name: 'basic-class',
      component: () => import('@/views/basic/subPages/class/index.vue'),
      meta: {
        title: '班级',
      },
    },
    {
      path: 'teacher',
      name: 'basic-teacher',
      component: () => import('@/views/basic/subPages/teacher/index.vue'),

      meta: {
        title: '教师',
      },
    },
    {
      path: 'teach',
      name: 'basic-teach',
      component: () => import('@/views/basic/subPages/teach/index.vue'),

      meta: {
        title: '任教',
      },
    },
    {
      path: 'student',
      name: 'basic-student',
      component: () => import('@/views/basic/subPages/student/index.vue'),

      meta: {
        title: '学生',
      },
    },

    // {
    //   path: 'parent',
    //   name: 'basic-parent',
    //   component: () => import('@/views/basic/subPages/parent/index.vue'),

    //   meta: {
    //     title: '家长',
    //   },
    // },
    {
      path: 'classroom',
      name: 'basic-classroom',
      component: () => import('@/views/basic/subPages/classroom/index.vue'),

      meta: {
        title: '教室',
      },
    },
    {
      path: 'upgrade',
      name: 'basic-upgrade',
      component: () => import('@/views/basic/subPages/upgrade/index.vue'),

      meta: {
        title: '升年级',
      },
    },
  ],
};

export default basicRoute;
