import { getLessonAllList, getLessonList } from '@/api/user';
import { getLessonId, setLessonId } from '@/utils/cookies.js';

const state = {
  lessonId: '',
  lessonName: '',
  lessonList: [],
};

const mutations = {
  setLessonItem(state, { lessonId = 0, lessonName = '无任教' }) {
    state.lessonId = lessonId;
    state.lessonName = lessonName;
    setLessonId(lessonId);
  },
  setLessonId(state, id) {
    state.lessonId = id;
    setLessonId(id);
  },
  setLessonName(state, name) {
    state.lessonName = name;
  },
  setLessonList(state, list) {
    state.lessonList = list;
  },
};

const actions = {
  async getLessons({ commit }) {
    const data = await Promise.all([getLessonList(), getLessonAllList()]);

    const currentList = data[0].result;
    const allList = data[1].result;
    commit('setLessonList', allList);

    const lessonIdStatus = typeof getLessonId() === 'undefined';
    let selectedItem = {
      lessonId: 0,
      lessonName: '无任教',
    };

    if (lessonIdStatus && currentList && currentList.length > 0) {
      selectedItem = currentList[0];
    } else {
      const lessonId = Number(getLessonId());
      for (let i = 0; i < allList.length; i++) {
        if (lessonId === allList[i].lessonId) {
          selectedItem = allList[i];
          break;
        }
      }
    }
    commit('setLessonItem', selectedItem);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
