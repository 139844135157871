<template>
  <el-dialog
    class="batchProgress"
    custom-class="ql-dialog "
    title="批量录入"
    :show-close="false"
    :close-on-press-escape="false"
    :visible.sync="visible"
    top="35vh"
    append-to-body
    width="785px"
  >
    <el-steps :active="activeType" finish-status="success" align-center>
      <el-step title="上传数据至服务器" icon=""></el-step>
      <el-step title="数据校验"></el-step>
      <el-step title="数据录入"></el-step>
      <el-step title="已完成"></el-step>
    </el-steps>
    <span slot="footer">
      <el-button @click="handleCancel" type="primary" size="small" v-show="activeType === -1">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Dialog, Steps, Step, Button } from 'element-ui';
export default {
  data() {
    return {
      percentage: 50,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    activeType: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  components: {
    [Dialog.name]: Dialog,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
  },
  methods: {
    handleCancel() {
      this.$emit('cancel', 'cancelError');
    },
  },
};
</script>

<style lang="scss" scoped>
.batchProgress {
  &::v-deep {
    .el-dialog__body {
      padding: 72px 32px;
    }

    .el-step__head.is-success {
      color: #ffffff;
      border-color: #38c3a1;

      .el-step__line {
        background-color: #38c3a1;
      }

      .el-step__icon {
        background: #38c3a1;
      }
    }

    .el-step__head.is-process {
      color: #ffffff;
      border-color: #38c3a1;

      .el-step__line {
        background-color: #38c3a1;
      }

      .el-step__icon {
        background: #38c3a1;
      }
    }

    .el-step__head.is-wait {
      // color: #8b8f8f;
      border-color: #efefef;

      .el-step__line {
        background-color: #efefef;
      }

      .el-step__icon {
        background: #f1f1f1;
      }
    }

    .el-step__main {
      padding-top: 16px;
      .el-step__title {
        font-size: 14px;
        font-weight: 400;
        color: #2c2f2e;
      }

      .is-success {
        color: #2c2f2e;
      }

      .is-process {
        color: #38c3a1;
      }
    }
  }
}
</style>
