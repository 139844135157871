/**
 * @name checkQuote
 * @desc 检查输入的字符是否具有特殊字符
 * @param {String} str 字符串
 * @returns {Boolean} true/false; false表示包含特殊字符
 */
export const checkQuote = (str) => {
  let items = new Array('~', '`', '!', '@', '#', '$', '%', '^', '&', '*', '{', '}', '[', ']', '(', ')');
  items.push(':', ';', "'", '|', '\\\\', '<', '>', '?', '/', '<<', '>>', '||', '//');
  items.push('admin', 'administrators', 'administrator', '管理员', '系统管理员');
  items.push('select', 'delete', 'update', 'insert', 'create', 'drop', 'alter', 'trancate');

  str = str.toLowerCase();

  for (var i = 0; i < items.length; i++) {
    if (str.includes(items[i])) {
      return false;
    }
  }

  return true;
};

/**
 * @name checkPassword
 * @desc 检查密码
 * @param {String} password
 * @returns {Boolean|String} true/String; String为error message
 */
export const checkPassword = (password) => {
  if (password.trim().length === 0) {
    return '密码不能为空';
  }
  if (password.includes(' ')) {
    return '密码不能输入空格';
  }
  const re =
    /^(((?=.*[0-9])(?=.*[a-zA-Z])|(?=.*[0-9])(?=.*[^0-9a-zA-Z])|(?=.*[a-zA-Z])(?=.*[^0-9a-zA-Z]))[^\s\u4e00-\u9fa5]+)$/;

  if (password.length < 8 || password.length > 24 || !re.test(password)) {
    return '8-24位，至少包含数字、字母或特殊字符中两种方式';
  }
  if (/<.*>/.test(password.trim())) {
    return '不允许输入非法字符';
  }
  return true;
};

/**
 * @name checkHtml
 * @desc 检查输入的字符是否包含html标签
 * @param {String} htmlStr 字符串
 * @returns {Boolean} true/false; false表示包含特殊字符
 */
export const checkHtml = (htmlStr) => {
  const reg = /<[^>]+>/g;
  return reg.test(htmlStr);
};

/**
 * @name checkPhone
 * @desc 检查手机号码
 * @param {String} phone
 * @returns {Boolean|String} true/String; String为error message
 */
export const checkPhone = (phone) => {
  if (!phone || phone.trim().length === 0) {
    return '请填写手机号码';
  }
  if (!/^1\d{10}$/.test(phone)) {
    return '手机号码格式不正确';
  }
  return true;
};

/**
 * @name validatePhone
 * @desc 检查手机号码是否合法
 *       正则表达式来源：https://github.com/VincentSit/ChinaMobilePhoneNumberRegex/blob/master/README-CN.md
 * @param {String} phone
 * @returns {Boolean|String} true/String; String为error message
 */
export const validatePhone = (phone) => {
  const regex =
    /^(?:\+?86)?1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[0-35-9]\d{2}|4(?:0\d|1[0-2]|9\d))|9[0-35-9]\d{2}|6[2567]\d{2}|4(?:(?:10|4[01])\d{3}|[68]\d{4}|[579]\d{2}))\d{6}$/;
  if (regex.test(phone)) {
    return false;
  }
  return true;
};
